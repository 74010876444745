import styled from '@emotion/styled';

interface LoginFormStyle {
  isNotConfirmedEmail?: boolean;
}

const LoginFormStyle = styled.div<LoginFormStyle>`
  display: flex;
  flex-direction: column;
  position: absolute;
  max-height: 380px;
  max-width: 330px;
  width: 100%;
  top: 45.16%;
  left: 67.5%;
  background-color: #fff;
  padding: 30px;
  outline: none;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.19), 0 0px 0px rgba(0, 0, 0, 0.23);
  .logo--login {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate3d(-50%, -50%, 0);
    max-width: 70px;
    max-height: 70px;
    width: 100%;
    height: 100%;
  }
  .login--title {
    font-size: 24px;
    color: #1d2332;
    line-height: 30px;
    font-family: RubikMedium;
  }
  .login--description {
    font-family: RubikRegular;
    color: #a5a7ad;
    font-size: 13px;
    line-height: 18px;
    word-break: break-word;
    p {
      margin: 0;
    }
    .email-typed {
      font-family: RubikBold;
      color: #1d2332;
    }
  }
  .group--login {
    .input--control {
      span {
        color: #a5a7ad;
        font-size: 14px;
        line-height: 15px;
        font-family: RubikRegular;
      }
      input {
        padding: 28px 20px 18px 20px;
        font-size: 14px;
        line-height: 1.28;
        font-family: RubikMedium;
      }
    }
    span {
      color: red;
      font-size: 11px;
    }
    .btn--control {
      padding: 8px 30px;
      span {
        font-family: RubikMedium;
        font-size: 15px;
        line-height: 18px;
        color: #1d2332;
      }
      img {
        width: 15.5px;
        height: 10px;
      }
      .font--svg {
        margin-right: 0;
        transform: translateX(25%);
      }
    }
    .alert-message {
      font-family: RubikRegular;
      color: #a5a7ad;
      font-size: 13px;
      line-height: 18px;
      word-break: break-word;
    }
  }
  .footer--form {
    max-height: 63px;
    height: 100%;
    width: 100%;
    background-color: #f7f7f7;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    box-shadow: 0 7px 4px -2px rgba(0, 0, 0, 0.1);
    a {
      text-decoration: none;
      color: #1d2332;
      font-size: 13px;
      line-height: 15px;
      font-family: RubikMedium;
      border-bottom: 1px solid rgba(97, 97, 97, 0.31);
      padding-bottom: 4.8px;
    }
  }
  @media only screen and (max-width: 1120px) {
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, 25%, 0);
  }

  @media only screen and (max-width: 425px) {
    max-width: 260px;
    padding: 27px;
    .logo--login {
      max-width: 53px;
      max-height: 53px;
    }
    .login--title {
      font-size: 18px;
      line-height: 1.6666;
      margin-top: 22px;
    }
    .login--description {
      margin: 0;
    }
    .group--login {
      margin: 17px 0 0 0;
      ${props => props.isNotConfirmedEmail && 'margin: 0'};
      .input--control {
        span {
        }
      }
      .btn--control {
        padding: 1.5px 18px;
        margin-top: 22px;
        margin-bottom: 25px;
      }
    }
    .footer--form {
      max-height: 47px;
      bottom: 0;
      transform: translateY(50%);
    }
  }
`;

export { LoginFormStyle };
