import React, { FunctionComponent } from 'react';
import { Field, useField } from 'react-final-form';
import Input from '../Input';
import { css } from '@emotion/core';

interface FormFieldProps {
  label: string;
  name: string;
  showError?: boolean;
  className?: string;
  type?: string;
  value?: string;
  fontSize?: number;
  onChangeValue?: (value: string) => void;
  offsetTopMoved?: string;
  required?: boolean;
  validate?: any;
  component?: any;
  rows?: number;
  maxLength?: number;
  maxDate?: Date;
  widthPercent?: string;
  country?: string;
  enableAreaCodes?: boolean;
}

interface ErrorProps {
  name: string
}

const Error: FunctionComponent<ErrorProps> = props => {
  const { name } = props;
  const {
    meta: { touched, error },
  } = useField(name, { subscription: { touched: true, error: true } });
  return touched && error ? (
    <span
      css={css`
        color: red;
        font-size: 12px;
    `}
    >{error}</span>
  ) : null
}

const FormField: FunctionComponent<FormFieldProps> = props => {
  const { name, widthPercent } = props;
  const showError = true;
  return (
    <div
      css={css`
        margin-bottom: 1rem;
        width: ${widthPercent ? widthPercent : '100%'};

        @media only screen and (max-width: 800px) {
          width: 100%;
        }
      `}>
      <Field component={Input} {...props} />
      {showError && <Error name={name} />}
    </div>
  )
}

export default FormField
