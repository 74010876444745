import React, { FunctionComponent, useState, useEffect } from 'react';
import Header from './Header';
import Footer, { FooterRegisterPage, FooterGalleryPage, FooterPhotographerPage, FooterLandingPage, FooterCheckOutPage } from './Footer';
import GlobalStyle from '../components/GlobalStyle';
import { ContainerStyle } from './LayoutStyles';
import { isMobile } from 'react-device-detect';

interface LayoutProps {
  isGuestPage?: boolean;
  isLandingPage?: boolean;
  isClientPage?: boolean;
  isPhotographerPage?: boolean;
  isRegisterPage?: boolean;
  isFAQPage?: boolean;
  isCheckoutPage?: boolean;
  isContactPage?: boolean;
  isAboutUsPage?: boolean;
  onRefEvent?: (e: HTMLDivElement) => void;
}

const Layout: FunctionComponent<LayoutProps> = props => {
  const {
    children,
    isLandingPage,
    onRefEvent,
    isClientPage,
    isPhotographerPage,
    isGuestPage,
    isRegisterPage,
    isFAQPage,
    isContactPage,
    isAboutUsPage,
    isCheckoutPage,
  } = props;

  const [isMobileDevice, setIsMobileDevice] = useState(false);

  useEffect(() => {
    setIsMobileDevice(isMobile);
  }, []);

  return (
    <>
      <GlobalStyle />
      <ContainerStyle isMobile={isMobileDevice}
        ref={r => {
          if (!onRefEvent || !r) return;
          onRefEvent(r);
        }}
      >
        <Header
          isLandingPage={isLandingPage}
          isClientPage={isClientPage}
          isPhotographerPage={isPhotographerPage}
          isGuestPage={isGuestPage}
          isRegisterPage={isRegisterPage}
          isFAQPage={isFAQPage}
          isCheckoutPage={isCheckoutPage}
          isContactPage={isContactPage}
          isAboutUsPage={isAboutUsPage}
        />
        {children}
        {isRegisterPage ? <FooterRegisterPage /> : isClientPage ? <FooterGalleryPage /> : isPhotographerPage ? <FooterPhotographerPage /> : isLandingPage ? <FooterLandingPage /> : isCheckoutPage ? <FooterCheckOutPage /> : <Footer alignLeft={isAboutUsPage || isFAQPage || isContactPage || isCheckoutPage} />}
      </ContainerStyle>
    </>
  );
};

export default Layout;
