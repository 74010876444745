import React, { FunctionComponent } from 'react';
import { Link } from 'gatsby';

import { connect } from 'react-redux';
import { FooterStyled, FooterRegisterStyled, FooterGalleryStyled, FooterPhotographerStyled, FooterLandingStyled, FooterCheckedOutStyled } from './LayoutStyles';
import { ActionBase } from '../reducers';
import { saveRef } from '../reducers/gallery/action';

interface FooterProps {
  saveRefAction: (name: string, ref: HTMLElement) => ActionBase;
  alignLeft?: boolean;
}

const Footer: FunctionComponent<FooterProps> = props => {
  const { saveRefAction, alignLeft } = props;
  return (
    <FooterStyled
      ref={r => {
        if (!r) return;
        saveRefAction('footerRef', r);
      }}
      className="footer--control"
      alignLeft={alignLeft}
    >
      <Link to="/">
        <div className="logo__container">
          <img src="/logo-main.svg" alt="travelpix" />
        </div>
      </Link>
      <ul className="bot-nav">
        <Link className="footer-link" to="/photographer">
          Photographers
        </Link>
        <Link className="footer-link" to="/aboutus">
          About
        </Link>
        <Link className="footer-link" to="/contact">
          Contact
        </Link>
        <Link className="footer-link" to="/terms-and-conditions">
          Terms
        </Link>
        <Link className="footer-link" to="/privacy-policy">
          Privacy
        </Link>
        <Link className="footer-link" to="/cookie-policy">
          Cookies
        </Link>
        <Link className="footer-link" to="/faq">
          FAQ
        </Link>
      </ul>
    </FooterStyled>
  );
};

const FooterRegisterPage: FunctionComponent<{}> = () => {
  return (
    <FooterRegisterStyled className="footer--control">
      <Link to="/">
        <div className="logo__container">
          <img src="/logo-main.svg" alt="travelpix" />
        </div>
      </Link>
      <ul className="bot-nav">
        <Link className="footer-link" to="/photographer">
          Photographers
        </Link>
        <Link className="footer-link" to="/aboutus">
          About
        </Link>
        <Link className="footer-link" to="/contact">
          Contact
        </Link>
        <Link className="footer-link" to="/terms-and-conditions">
          Terms
        </Link>
        <Link className="footer-link" to="/privacy-policy">
          Privacy
        </Link>
        <Link className="footer-link" to="/cookie-policy">
          Cookies
        </Link>
        <Link className="footer-link" to="/faq">
          FAQ
        </Link>
      </ul>
    </FooterRegisterStyled>
  );
};

const FooterGalleryPage: FunctionComponent<{}> = () => {
  return (
    <FooterGalleryStyled className="footer--control">
      <Link to="/">
        <div className="logo__container">
          <img src="/logo-main.svg" alt="travelpix" />
        </div>
      </Link>
      <ul className="bot-nav">
        <Link className="footer-link" to="/photographer">
          Photographers
        </Link>
        <Link className="footer-link" to="/aboutus">
          About
        </Link>
        <Link className="footer-link" to="/contact">
          Contact
        </Link>
        <Link className="footer-link" to="/terms-and-conditions">
          Terms
        </Link>
        <Link className="footer-link" to="/privacy-policy">
          Privacy
        </Link>
        <Link className="footer-link" to="/cookie-policy">
          Cookies
        </Link>
        <Link className="footer-link" to="/faq">
          FAQ
        </Link>
      </ul>
    </FooterGalleryStyled>
  );
};

const FooterPhotographerPage: FunctionComponent<{}> = () => {
  return (
    <FooterPhotographerStyled className="footer--control">
      <Link to="/">
        <div className="logo__container">
          <img src="/logo-main.svg" alt="travelpix" />
        </div>
      </Link>
      <ul className="bot-nav">
        <Link className="footer-link" to="/photographer">
          Photographers
        </Link>
        <Link className="footer-link" to="/aboutus">
          About
        </Link>
        <Link className="footer-link" to="/contact">
          Contact
        </Link>
        <Link className="footer-link" to="/terms-and-conditions">
          Terms
        </Link>
        <Link className="footer-link" to="/privacy-policy">
          Privacy
        </Link>
        <Link className="footer-link" to="/cookie-policy">
          Cookies
        </Link>
        <Link className="footer-link" to="/faq">
          FAQ
        </Link>
      </ul>
    </FooterPhotographerStyled>
  );
};

const FooterLandingPage: FunctionComponent<{}> = () => {
  return (
    <FooterLandingStyled className="footer--control">
      <Link to="/">
        <div className="logo__container">
          <img src="/logo-main.svg" alt="travelpix" />
        </div>
      </Link>
      <ul className="bot-nav">
        <Link className="footer-link" to="/photographer">
          Photographers
        </Link>
        <Link className="footer-link" to="/aboutus">
          About
        </Link>
        <Link className="footer-link" to="/contact">
          Contact
        </Link>
        <Link className="footer-link" to="/terms-and-conditions">
          Terms
        </Link>
        <Link className="footer-link" to="/privacy-policy">
          Privacy
        </Link>
        <Link className="footer-link" to="/cookie-policy">
          Cookies
        </Link>
        <Link className="footer-link" to="/faq">
          FAQ
        </Link>
      </ul>
    </FooterLandingStyled>
  );
};

const FooterCheckOutPage: FunctionComponent<{}> = () => {
  return (
    <FooterCheckedOutStyled className="footer--control">
      <Link to="/">
        <div className="logo__container">
          <img src="/logo-main.svg" alt="travelpix" />
        </div>
      </Link>
      <ul className="bot-nav">
        <Link className="footer-link" to="/photographer">
          Photographers
        </Link>
        <Link className="footer-link" to="/aboutus">
          About
        </Link>
        <Link className="footer-link" to="/contact">
          Contact
        </Link>
        <Link className="footer-link" to="/terms-and-conditions">
          Terms
        </Link>
        <Link className="footer-link" to="/privacy-policy">
          Privacy
        </Link>
        <Link className="footer-link" to="/cookie-policy">
          Cookies
        </Link>
        <Link className="footer-link" to="/faq">
          FAQ
        </Link>
      </ul>
    </FooterCheckedOutStyled>
  );
};

const mapDispatchToProps = {
  saveRefAction: saveRef,
};

export default connect(null, mapDispatchToProps)(Footer);
export { FooterRegisterPage, FooterGalleryPage, FooterPhotographerPage, FooterLandingPage, FooterCheckOutPage };
