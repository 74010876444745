import styled from '@emotion/styled';

interface ContainerStyleProps {
  isMobile: boolean;
}

const ContainerStyle = styled.div<ContainerStyleProps>`
  height: 100%;
  width: 100%;
  ${props => {
    return `position: ${props.isMobile ? 'static' : 'fixed'};`
  }}
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const HeaderCheckoutUsingStyle = styled.div`
  .container__header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 81px;

    .btn {
      width: auto;
      border-radius: 0;
      padding: 14px 40px;
      cursor: pointer;

      span {
        margin-left: 19.5px;
        font-family: RubikMedium;
      }
    }

    .btn--back {
      background-color: #f5f5f5;
      margin-left: 81px;
      margin: 1.56rem 0 1.56rem 1.56rem;

      span {
        font-size: 15px;
        line-height: 18px;
      }
    }

    .btn--secure {
      background-color: #fff;
      margin: 1.56rem 16px 1.56rem 0;
      cursor: none;
      cursor: not-allowed;
      pointer-events: all !important;

      span {
        font-size: 12px;
        line-height: 24px;
        margin-left: 11.5px;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .container__header {
      .btn {
        padding: 14px;
        span {
          margin-left: 5px;
        }
      }
      .btn--back {
        margin-left: 30px;
      }
    }
  }

  @media only screen and (max-width: 456px) {
    .container__header {
      .btn--back {
        margin-left: 10px;
        span {
          font-size: 11px;
        }
      }
      .btn--sercure {
        span {
          font-size: 10px;
        }
      }
    }
  }
`;

const HeadingRegisterUsingStyle = styled.div`
  background: #fbca00;
  min-height: 90px;
  width: 100%;
  display: flex;

  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 2;

  @media screen and (min-width: 900px) and (max-width: 1024px) {
    padding-left: 6%;
    justify-content: start;
  }

  @media screen and (min-width: 1025px) and (max-width: 1300px) {
    padding-left: 14%;
    justify-content: start;
  }

  @media screen and (min-width: 1301px) and (max-width: 1450px) {
    padding-left: 18%;
    justify-content: start;
  }

  @media screen and (min-width: 1451px) and (max-width: 1700px) {
    padding-left: 23%;
    justify-content: start;
  }

  @media screen and (min-width: 1701px) {
    padding-left: 26%;
    justify-content: start;
  }

  .header-container {
    max-width: 917px;
    width: 100%;
    align-self: center;
    display: flex;

    .main-logo {
      max-height: 40px;
      width: auto;
      z-index: 1;
    }
    .menu-icon {
      display: none;
      cursor: pointer;
      position: absolute;
      top: 44px;
      right: 22px;
    }
    .icon--toggle {
      width: 22px;
      height: 9px;
    }
  }

  @media screen and (max-width: 768px) {
    min-height: 90px;
    .header-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 30px;

      .menu-icon {
        display: block;
      }
    }
  }
`;
const HeaderFAQPageUsingStyle = styled.div`
  background-color: #1d2332;

  min-height: 90px;
  width: 100%;

  position: fixed;
  z-index: 2;
  padding: 0 8%;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 768px) { // iPad and iPad mini
    padding: 0 30px;
  }

  @media screen and (min-width: 900px) and (max-width: 1024px) {
    padding-left: 6%;
    justify-content: start;
  }

  @media screen and (min-width: 1025px) and (max-width: 1300px) {
    padding-left: 14%;
    justify-content: start;
  }

  @media screen and (min-width: 1301px) and (max-width: 1450px) {
    padding-left: 18%;
    justify-content: start;
  }

  @media screen and (min-width: 1451px) and (max-width: 1700px) {
    padding-left: 23%;
    justify-content: start;
  }

  @media screen and (min-width: 1701px) {
    padding-left: 26%;
    justify-content: start;
  }

  .header-container {
    display: flex;
    align-items: center;

    width: 100%;
    max-width: 917px;

    padding: 24px 0;

    .menu-icon {
      display: none;
      .icon--toggle {
        width: 20px;
        height: 9px;
        margin: 0;

        img {
          width: 20px;
        }
      }
    }
    @media screen and (max-width: 768px) {
      justify-content: space-between;

      .menu-icon {
        display: block;
        max-width: 428px;
      }
    }

    @media screen and (max-width: 502px) {
      .menu-icon {
        max-width: 302px;
      }
    }
  }
`;

const HeaderPhotographerUsingStyle = styled.div`
  background: #fbca00;
  min-height: 90px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 2;

  @media screen and (min-width: 900px) and (max-width: 1024px) {
    padding-left: 6%;
    justify-content: start;
  }

  @media screen and (min-width: 1025px) and (max-width: 1300px) {
    padding-left: 14%;
    justify-content: start;
  }

  @media screen and (min-width: 1301px) and (max-width: 1450px) {
    padding-left: 18.5%;
    justify-content: start;
  }

  @media screen and (min-width: 1451px) and (max-width: 1700px) {
    padding-left: 23%;
    justify-content: start;
  }

  @media screen and (min-width: 1701px) {
    padding-left: 26.5%;
    justify-content: start;
  }

  .header-container {
    max-width: 917px;
    width: 100%;
    align-self: center;
    display: flex;

    .main-logo {
      max-height: 40px;
      width: auto;
      z-index: 1;
    }
    .menu-icon {
      display: none;
      cursor: pointer;
      position: absolute;
      top: 44px;
      right: 22px;
    }
    .icon--toggle {
      width: 22px;
      height: 9px;
    }
  }

  @media screen and (max-width: 824px) {
    -webkit-box-shadow: 0 2px 6px -4px black;
    -moz-box-shadow: 0 2px 6px -4px black;
         box-shadow: 0 2px 6px -4px black;
    min-height: 90px;

    .header-container {
      justify-content: space-between;
      padding-left: 30px;

      .menu-icon {
        display: block;
      }
    }
  }
`;

const HeaderForGuestStyle = styled.div`
  min-height: 110px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1d2332;
  z-index: 1;
  .header-container {
    max-width: 752px;
    width: 100%;
    align-self: center;
    .main-logo {
      max-height: 40px;
      width: auto;
      z-index: 1;
    }
    .menu-icon {
      display: none;
      width: 20px;
      height: 9px;
      cursor: pointer;
      margin: 0;
    }
    @media screen and (max-width: 840px) {
      padding: 0 2.5em;
    }
    @media screen and (max-width: 425px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .menu-icon {
        display: block;
      }
    }
  }
`;

const
  HeaderLandingStyle = styled.div`
  background: url('/hero-background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  min-height: 620px;
  width: 100%;
  .logo--wrapper {
    position: absolute;
    max-width: 982px;
    width: 100%;
    height: 54px;
    top: 55px;
    left: 50%;
    transform: translateX(-50%);
    .main-logo {
      position: absolute;
      max-height: 3rem;
      width: auto;
      top: 0;
      left: 18px;
      z-index: 1;
    }
  }
  .login--wrapper {
    position: absolute;
    max-width: 982px;
    width: 100%;
    bottom: 55%;
    left: 50%;
    transform: translateX(-50%);
  }
  .menu-icon {
    position: absolute;
    top: 25%;
    right: 0;
    transform: translateX(20%);
    z-index: 3;
    color: #fff;
    display: none;
    justify-content: center;
    align-items: center;

    .icon--toggle {
      width: 20px;
      height: 9px;
      margin: 0;
    }
  }
  .dark-bg {
    position: absolute;
    height: 100%;
    width: 100%;
    box-shadow: 0px 0px 90px 140px rgba(0, 0, 0, 0.14) inset;
  }

  .caption {
    padding-top: 15px;
    position: absolute;
    top: 48.38%;
    left: calc(62.5% - 6.35%);
    transform: translateX(-100%);
    color: #fff;
    max-width: 535px;
    font-size: 60px;
    line-height: 64px;
    z-index: 1;
    width: 100%;
    font-family: RubikMedium;
    text-shadow: 0px 2px 4px rgba(0,0,0,0.5);
  }

  @media only screen and (max-width: 1120px) {
    display: flex;
    justify-content: center;
    .caption {
      font-size: 30px;
      line-height: 1.13;
      max-width: 330px;
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -80%, 0);
    }
    .logo--wrapper {
      max-width: 330px;
      top: 30px;

      .main-logo {
        max-width: 180px;
        left: 0;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .login--wrapper {
      bottom: 51%;
    }

    .logo--wrapper {
      .menu-icon {
        display: block;
      }
    }
  }

  @media screen and (max-width: 425px) {
    .login--wrapper {
      bottom: 57%;
      transform: translateX(0);
      left: 0;
      width: calc(100% - 60px);

      .login--control {
        max-width: 100%;
        left: 30px;
        transform: translate3d(0,25%,0);
      }
    }
    min-height: 492px;
    .caption {
      max-width: 316px;
      left: 30px;
      transform: translate3d(0,-80%,0);
    }
    .logo--wrapper {
      left: 0;
      transform: translateX(0);
      margin-left: 30px;
      width: calc(100% - 68px);
      max-width: 100%;

      .menu-icon {
        display: block;
      }

      .main-logo {
        max-width: 180px;
        left: 0;
      }
    }
  }
`;

const HeaderClientUsingStyle = styled.div`
  min-height: 110px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.23);
  .header--wrapper {
    max-width: 1400px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0 32px;
  }
  .main-logo {
    max-height: 40px;
    width: auto;
    z-index: 1;
  }
  .feature-group {
    display: flex;
    justify-content: center;
    align-items: center;
    .add-all-photos {
      border: 2px solid #1d2332;
      font-size: 15px;
      font-weight: 500;
      font-family: RubikMedium;
      border-radius: 4px;
      padding: 12px 22px;
      background: #fff;
      cursor: pointer;
      margin: 0 1em;
      outline: none;
      display: flex;
      align-items: center;
      .added-photos {
        width: 15px;
        height: 12px;
        margin: 0;
        margin-right: 7px;
      }
    }
  }

  .back {
    max-width: 15em;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: #f1f1f1;
    padding: 14px 22px;
    .back-icon {
      width: 15.5px;
      height: 10px;
    }
    p {
      font-weight: 500;
      font-size: 15px;
      font-family: RubikMedium;
      margin: 0;
    }
  }
`;

const MenuBarStyle = styled.div`
  position: fixed;
  top: 0;
  z-index: 3;
  background: rgba(29, 35, 50, 0.54);
  width: 100%;
  height: 100%;
  transform: translateY(-100%);
  transition: transform 0.5s ease-in;
  opacity: 0;

  .menu-holder {
    background: #1d2332;
    height: 32rem;

    .menu-header {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }

    .menu-logo {
      margin-top: 36px;
    }

    .menu-icon {
      position: absolute;
      top: 45px;
      right: 22px;
    }

    .icon--toggle {
      width: 20px;
      height: 9px;
    }
  }
  &.active {
    opacity: 1;
    transform: translateY(0);
    transition: transform 0.5s ease-out, opacity 0.1s ease;
  }
  &.not-active {
    opacity: 0;
    transform: translateY(-100%);
    transition: transform 0.5s ease-out, opacity 1.5s ease;
  }
  span {
    color: rgba(255, 255, 255, 0.75);
    font-size: 80px;
    position: absolute;
    top: 3%;
    right: 8%;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    li {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 1.8rem 5rem 0 5rem;
      a {
        text-decoration: none;
        color: white;
        font-size: 15px;
        line-height: 18px;
        font-family: RubikMedium;
        transition: color 0.2s ease-in-out;
        &:hover {
          color: rgba(255, 255, 255, 1);
        }
      }
      &.black {
        color: black;
      }
    }
  }
`;

const ContextWrapperStyle = styled.div`
  height: 100%;
`;

interface FooterStyleProps {
  alignLeft?: boolean;
}

const FooterStyled = styled.footer<FooterStyleProps>`
  align-items: flex-end;
  min-height: 110px;
  width: 100%;
  padding: 0 8% 0 8%;
  background-color: #1d2332;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media screen and (width: 768px) { // iPad and iPad mini
    padding: 0 4% !important;
    justify-content: start !important;
  }

  @media screen and (min-width: 900px) and (max-width: 1024px) {
    padding-left: 6%;
  }

  @media screen and (min-width: 1025px) and (max-width: 1300px) {
    padding-left: 14%;
  }

  @media screen and (min-width: 1301px) and (max-width: 1450px) {
    padding-left: 18%;
  }

  @media screen and (min-width: 1451px) and (max-width: 1700px) {
    padding-left: 23%;
  }

  @media screen and (min-width: 1701px) {
    padding-left: 26%;
  }

  .bot-nav {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    color: #fff;
    .footer-link {
      margin-left: 1rem;
      color: #fff;
      text-decoration: none;
      font-family: RubikMedium;
    }
  }
  img {
    max-height: 40px;
    width: auto;
  }

  @media only screen and (max-width: 768px) and (min-width: 320px) {
  ${props => {
    return `justify-content: ${props.alignLeft ? 'left;' : 'center;'}`
  }}
  ${props => {
    return `padding: 0 8% 0 ${props.alignLeft ? '30px;' : '8%;'}`
  }}
    .bot-nav {
      display: none;
    }
  }
`;

const FooterRegisterStyled = styled.footer`
  align-items: flex-end;
  min-height: 110px;
  width: 100%;
  padding: 0 0 0 20%;
  background-color: #1d2332;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: 900px) and (max-width: 1024px) {
    padding-left: 6%;
  }

  @media screen and (min-width: 1025px) and (max-width: 1300px) {
    padding-left: 14%;
  }

  @media screen and (min-width: 1301px) and (max-width: 1450px) {
    padding-left: 18%;
  }

  @media screen and (min-width: 1451px) and (max-width: 1700px) {
    padding-left: 23%;
  }

  @media screen and (min-width: 1701px) {
    padding-left: 26%;
  }

  .logo__container {
    max-width: 770px;
    width: 100%;
  }
  .bot-nav {
    display: flex;
    position: absolute;
    right: 10%;
    list-style-type: none;
    margin: 0;
    padding: 0;
    color: #fff;
    .footer-link {
      margin-left: 1rem;
      color: #fff;
      text-decoration: none;
      font-family: RubikMedium;
    }
  }
  img {
    max-height: 40px;
    width: auto;
  }

  @media only screen and (max-width: 768px) {
    padding-left: 30px;
    justify-content: flex-start;

    .logo__container {
      display: flex;
      justify-content: center;
    }
    .bot-nav {
      display: none;
    }
  }
`;

const FooterGalleryStyled = styled.footer`
  align-items: flex-end;
  min-height: 110px;
  width: 100%;
  padding: 0 0 0 8%;
  background-color: #1d2332;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: 1024px) {
    padding-left: 5%;
    justify-content: start;
  }

  @media screen and (min-width: 1025px) and (max-width: 1550px) {
    padding-left: 2%;
    justify-content: start;
  }

  @media screen and (min-width: 1551px) and (max-width: 1800px) {
    padding-left: 8.5%;
    justify-content: start;
  }

  @media screen and (min-width: 1881px) {
    padding-left: 14%;
    justify-content: start;
  }

  .logo__container {
    max-width: 770px;
    width: 100%;
  }
  .bot-nav {
    display: flex;
    position: absolute;
    right: 10%;
    list-style-type: none;
    margin: 0;
    padding: 0;
    color: #fff;
    .footer-link {
      margin-left: 1rem;
      color: #fff;
      text-decoration: none;
      font-family: RubikMedium;
    }
  }
  img {
    max-height: 40px;
    width: auto;
  }

  @media only screen and (max-width: 1024px) {
    padding-left: 1.3rem;
  }
  @media only screen and (max-width: 768px) {
    padding-left: 0;
    justify-content: center;
    .logo__container {
      display: flex;
      justify-content: center;
    }
    .bot-nav {
      display: none;
    }
  }
`;

const FooterPhotographerStyled = styled.footer`
align-items: flex-end;
  min-height: 110px;
  width: 100%;
  padding: 0 8% 0 15%;
  background-color: #1d2332;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: 900px) and (max-width: 1024px) {
    padding-left: 6%;
  }

  @media screen and (min-width: 1025px) and (max-width: 1300px) {
    padding-left: 14%;
  }

  @media screen and (min-width: 1301px) and (max-width: 1450px) {
    padding-left: 18.5%;
  }

  @media screen and (min-width: 1451px) and (max-width: 1700px) {
    padding-left: 23%;
  }

  @media screen and (min-width: 1701px) {
    padding-left: 26.5%;
  }

  .bot-nav {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    color: #fff;
    .footer-link {
      margin-left: 1rem;
      color: #fff;
      text-decoration: none;
      font-family: RubikMedium;
    }

    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
  img {
    max-height: 40px;
    width: auto;
  }

  @media only screen and (max-width: 824px) {
    padding-left: 0;
    justify-content: flex-start;
    padding-left: 30px;

    .logo__container {
      display: flex;
      justify-content: center;
    }
    .bot-nav {
      display: none;
    }
  }
`;

const FooterLandingStyled = styled.footer<FooterStyleProps>`
  align-items: flex-end;
  min-height: 110px;
  width: 100%;
  padding: 0 8% 0 8%;
  background-color: #1d2332;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: 900px) and (max-width: 1024px) {
    padding-left: 6%;
  }

  @media screen and (min-width: 1025px) and (max-width: 1300px) {
    padding-left: 13%;
  }

  @media screen and (min-width: 1301px) and (max-width: 1450px) {
    padding-left: 17%;
  }

  @media screen and (min-width: 1451px) and (max-width: 1700px) {
    padding-left: 21.9%;
  }

  @media screen and (min-width: 1701px) {
    padding-left: 25.2%;
  }

  .bot-nav {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    color: #fff;
    .footer-link {
      margin-left: 1rem;
      color: #fff;
      text-decoration: none;
      font-family: RubikMedium;
    }
  }
  img {
    max-height: 40px;
    width: auto;
  }

  @media only screen and (max-width: 768px) and (min-width: 320px) {
    justify-content: left;
    padding: 0 8% 0 30px;

    .bot-nav {
      display: none;
    }
  }
`;

const FooterCheckedOutStyled = styled.footer`
align-items: flex-end;
  min-height: 110px;
  width: 100%;
  padding: 0 8% 0 15%;
  background-color: #1d2332;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: 900px) and (max-width: 1024px) {
    padding-left: 10%;
  }

  @media screen and (min-width: 1025px) and (max-width: 1300px) {
    padding-left: 8.5%;
  }

  @media screen and (min-width: 1301px) and (max-width: 1450px) {
    padding-left: 7.2%;
  }

  @media screen and (min-width: 1451px) and (max-width: 1700px) {
    padding-left: 6%;
  }

  @media screen and (min-width: 1701px) {
    padding-left: 6%;
  }

  .bot-nav {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    color: #fff;
    .footer-link {
      margin-left: 1rem;
      color: #fff;
      text-decoration: none;
      font-family: RubikMedium;
    }

    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
  img {
    max-height: 40px;
    width: auto;
  }

  @media only screen and (max-width: 824px) {
    padding-left: 0;
    justify-content: flex-start;
    padding-left: 30px;

    .logo__container {
      display: flex;
      justify-content: center;
    }
    .bot-nav {
      display: none;
    }
  }
`;


export {
  HeaderPhotographerUsingStyle,
  HeaderLandingStyle,
  HeaderClientUsingStyle,
  HeaderForGuestStyle,
  HeadingRegisterUsingStyle,
  ContextWrapperStyle,
  FooterStyled,
  FooterRegisterStyled,
  MenuBarStyle,
  ContainerStyle,
  HeaderFAQPageUsingStyle,
  HeaderCheckoutUsingStyle,
  FooterGalleryStyled,
  FooterPhotographerStyled,
  FooterLandingStyled,
  FooterCheckedOutStyled
};
