import React, { FunctionComponent, useState } from 'react';
import { css } from '@emotion/core';
import { Link } from '@reach/router';
import { LoginFormStyle } from './LoginFormStyle';
import Button from '../Form/Button';
import ImageIcon from '../ImageIcon';
import FormField from '../../components/Form/FormField';
import { Form } from 'react-final-form';
import api from '../../services/api';

const emailRegrex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const emailNotConfirmed = "Looks like you forgot to confirm your email. We've re-sent a confirmation link.";

interface LoginFormProps {
  className?: string;
}

const LoginForm: FunctionComponent<LoginFormProps> = props => {
  const { className } = props;
  const [email, setEmail] = useState('');
  const [isNotConfirmedEmail, setNotConfirmedEmail] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const onSubmitForm = (values: object) => {
     // Call API
     api({
      method: 'post',
      url: `/auth/login-by-email`,
      data: {
        email: values.email
      }
    })
      .then(response => {
        console.log(response);

        if (response.data.message === emailNotConfirmed) {
          setNotConfirmedEmail(true);
        } else {
          setAlertMessage(response.data.message);
        }

      })
      .catch(error => {
        console.log(error);
        setNotConfirmedEmail(true);
      });
  }

  const onChangeValue = (value: string) => {
    setEmail(value);
  };

  return (
    <LoginFormStyle className={className} isNotConfirmedEmail={isNotConfirmedEmail}>
      <img className="logo--login" src="/logo.svg" alt="travelpix-yellow-logo" />
      <p
        className="login--title"
        css={
          isNotConfirmedEmail
            ? css`
                margin: 27px 0 0 0;
              `
            : css`
                margin: 28px 0 0 0;
              `
        }
      >
        {isNotConfirmedEmail ? "Uh-oh... your account hasn't been confirmed!" : 'Login to your TravelPix'}
      </p>
      {!isNotConfirmedEmail && <p className="login--description">Your login details can be found in your confirmation email.</p>}
      {isNotConfirmedEmail && (
        <p className="login--description">
          Looks like you forgot to confirm your email. We've re-sent a confirmation link to <span className="email-typed">{email}</span>...
          Once you've clicked it. You will be taken directly to your photos.
        </p>
      )}
    <Form
      onSubmit={onSubmitForm}
      initialValues={{ email: '' }}
      validate={values => {
        const errors = {};
        if (!values.email || !emailRegrex.test(values.email)) {
          errors.email = 'Please input a valid email e.g. name@example.com';
        }
        return errors;
      }}
      render={({ handleSubmit }) => {
        return (
          <form
          className="group--login"
            onSubmit={handleSubmit}
            >
        {!isNotConfirmedEmail && (
          <FormField
            label="Email (name@example.com)"
            name="email"
            type="email"
            value={email}
            onChangeValue={onChangeValue}
            className="input--control"
            offsetTopMoved="11px"
          />
        )}
         {!isNotConfirmedEmail && <span className="alert-message">{alertMessage}</span>}
        <Button
          label={isNotConfirmedEmail ? 'Try Again' : 'Continue'}
          icon={<ImageIcon src="/icon/right_arrow.svg" className="font--svg" />}
          className="btn--control"
          type="submit"
          css={
            alertMessage ? css `margin-top: 16px;` :
            isNotConfirmedEmail
              ? css`
                  margin-top: 33px;
                `
              : css`
                  margin-top: 32px;
                `
          }
        />
      </form>
        )}} />
      <div
        className="btn footer--form"
        css={
          isNotConfirmedEmail
            ? css`
                transform: translateY(37px);
              `
            : css`
                transform: translateY(33px);
              `
        }
      >
        <Link to="/photographer">Become a TravelPix Photographer</Link>
      </div>
    </LoginFormStyle>
  );
};

export default LoginForm;
