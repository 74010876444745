import React, { FunctionComponent, useState } from 'react';
import { navigate } from '@reach/router';
import { connect, useDispatch, useSelector } from 'react-redux';
import { css } from '@emotion/core';
import { MdLock } from 'react-icons/md';
import LoginForm from '../components/LoginForm';
import {
  HeaderForGuestStyle,
  HeaderPhotographerUsingStyle,
  HeaderLandingStyle,
  HeaderClientUsingStyle,
  HeadingRegisterUsingStyle,
  HeaderFAQPageUsingStyle,
  HeaderCheckoutUsingStyle,
  MenuBarStyle
} from './LayoutStyles';
import ImageIcon from '../components/ImageIcon';
import { RootState, ActionBase } from '../reducers';
import { saveRef, checkDetailPage, checkOpenNav } from '../reducers/gallery/action';
import { eventEmitter } from '../config/eventEmitter';
import Button from '../components/Form/Button';
import { navWidth } from '../utils/constant';

interface HeaderProps {
  isGuestPage?: boolean;
  isLandingPage?: boolean;
  isClientPage?: boolean;
  isPhotographerPage?: boolean;
  isRegisterPage?: boolean;
  isFAQPage?: boolean;
  isContactPage?: boolean;
  isAboutUsPage?: boolean;
  isCheckoutPage?: boolean;
  refs: { [key: string]: HTMLDivElement };
  isShowMorePhotos?: boolean;
  saveRefAction: (name: string, ref: HTMLElement) => ActionBase;
  checkDetailPage?: (isSlider: boolean) => ActionBase;
  checkOpenNav?: (isOpenNav: boolean) => ActionBase;
  isOpenNav?: boolean;
}

const HeaderCheckoutUsing: FunctionComponent<{}> = () => {
  const onGoToYourCart = () => {
    // navigate('/gallery');
    location.href = '/gallery';
  }

  return (
    <HeaderCheckoutUsingStyle>
      <div className="container__header">
        <Button
          label="Back to your cart"
          className="btn btn--back"
          icon={<img src="/icon/black_left_arrow.svg" alt="black_left_arrow.svg" />}
          reverse
          onClick={onGoToYourCart}
        />
        <Button label="SECURE CHECKOUT" className="btn btn--secure" icon={<MdLock />} reverse />
      </div>
    </HeaderCheckoutUsingStyle>
  );
};

const HeadingRegisterUsing: FunctionComponent<HeaderProps> = () => {
  const [isOpen, setOpen] = useState(false);

  const toggleMenuBar = () => {
    const bodyEl = document.body;
    if (!isOpen) {
      bodyEl.style.overflow = 'hidden';
    } else {
      bodyEl.style.overflow = 'auto';
    }
    setOpen(!isOpen);
  };

  return (
    <HeadingRegisterUsingStyle
      css={css`
        @media screen and (max-width: 768px) {
          background: #fbca00;
        }
      `}
    >
      <div className="header-container">
        <img
          className="main-logo"
          src="/logo-navy.svg"
          alt="Logo Navy"
          onClick={() => {
            navigate('/');
          }}
        />
        <div className={isOpen ? 'menu-icon active' : 'menu-icon not-active'}>
          <ImageIcon onClick={toggleMenuBar} src="/icon/black_menu_icon.svg" className="icon--toggle" />
        </div>
      </div>

      <MenuBarStyle className={`${isOpen ? 'active' : 'not-active'}`}>
        <div
          className="menu-holder"
          css={css`
            background: #fbca00 !important;
          `}>
          <div className="menu-header">
            <img className="menu-logo" src="/icon/logo-black.svg" alt="Logo" />
          </div>
          <div className="menu-icon">
            <ImageIcon onClick={toggleMenuBar} src="/icon/black_menu_icon.svg" className="icon--toggle" />
          </div>
          <ul>
            <li>
              <a
                href="/"
                css={css`
                  color: black !important;
              `}>Home
              </a>
            </li>
            <li>
              <a
                href="/photographer"
                css={css`
                  color: black !important;
              `}>
                Photographers
              </a>
            </li>
            <li>
              <a
                href="/aboutus"
                css={css`
                  color: black !important;
                `}>
                About
              </a>
            </li>
            <li>
              <a
                href="/contact"
                css={css`
                  color: black !important;
                `}>
                Contact
              </a>
            </li>
            <li>
              <a
                href="/terms-and-conditions"
                css={css`
                  color: black !important;
              `}>
                Terms
              </a>
            </li>
            <li>
              <a
                href="/privacy-policy"
                css={css`
                  color: black !important;
              `}>
                Privacy
              </a>
            </li>
            <li>
              <a
                href="/cookie-policy"
                css={css`
                  color: black !important;
              `}>
                Cookies
              </a>
            </li>
            <li>
              <a
                href="/faq"
                css={css`
                  color: black !important;
              `}>
                FAQ
              </a>
            </li>
          </ul>
        </div>
      </MenuBarStyle>
    </HeadingRegisterUsingStyle>
  );
};

const HeadingPhotographerUsing: FunctionComponent<HeaderProps> = () => {
  const [isOpen, setOpen] = useState(false);

  const toggleMenuBar = () => {
    const bodyEl = document.body;
    if (!isOpen) {
      bodyEl.style.overflow = 'hidden';
    } else {
      bodyEl.style.overflow = 'auto';
    }
    setOpen(!isOpen);
  };

  return (
    <HeaderPhotographerUsingStyle
      css={css`
        @media screen and (max-width: 768px) {
          background: #fbca00;
        }
    `}
    >
      <div className="header-container">
        <img
          className="main-logo"
          src="/logo-navy.svg"
          alt="Logo Navy"
          onClick={() => {
            navigate('/');
          }}
        />
        <div className={isOpen ? 'menu-icon active' : 'menu-icon not-active'}>
          <ImageIcon onClick={toggleMenuBar} src="/icon/black_menu_icon.svg" className="icon--toggle" />
        </div>
      </div>

      <MenuBarStyle className={`${isOpen ? 'active' : 'not-active'}`}>
        <div
          className="menu-holder"
          css={css`
            background: #fbca00 !important;
          `}>
          <div className="menu-header">
            <img className="menu-logo" src="/icon/logo-black.svg" alt="Logo" />
          </div>
          <div className="menu-icon">
            <ImageIcon onClick={toggleMenuBar} src="/icon/black_menu_icon.svg" className="icon--toggle" />
          </div>
          <ul>
            <li>
              <a
                href="/"
                css={css`
                  color: black !important;
              `}>Home
              </a>
            </li>
            <li>
              <a
                href="/photographer"
                css={css`
                  color: black !important;
              `}>
                Photographers
              </a>
            </li>
            <li>
              <a
                href="/aboutus"
                css={css`
                  color: black !important;
                `}>
                About
              </a>
            </li>
            <li>
              <a
                href="/contact"
                css={css`
                  color: black !important;
                `}>
                Contact
              </a>
            </li>
            <li>
              <a
                href="/terms-and-conditions"
                css={css`
                  color: black !important;
              `}>
                Terms
              </a>
            </li>
            <li>
              <a
                href="/privacy-policy"
                css={css`
                  color: black !important;
              `}>
                Privacy
              </a>
            </li>
            <li>
              <a
                href="/cookie-policy"
                css={css`
                  color: black !important;
              `}>
                Cookies
              </a>
            </li>
            <li>
              <a
                href="/faq"
                css={css`
                  color: black !important;
              `}>
                FAQ
              </a>
            </li>
          </ul>
        </div>
      </MenuBarStyle>
    </HeaderPhotographerUsingStyle>
  );
};

const HeaderForGuest: FunctionComponent = () => {
  return (
    <HeaderForGuestStyle>
      <div className="header-container">
        <img
          className="main-logo"
          src="/logo-main.svg"
          alt="Logo White"
          onClick={() => {
            navigate('/');
          }}
        />
        <ImageIcon className="menu-icon" src="/icon/menu_icon.svg" />
      </div>
    </HeaderForGuestStyle>
  );
};

const HeaderLanding = () => {
  const [isOpen, setOpen] = useState(false);

  const toggleMenuBar = () => {
    const bodyEl = document.body;
    if (!isOpen) {
      bodyEl.style.overflow = 'hidden';
    } else {
      bodyEl.style.overflow = 'auto';
    }
    setOpen(!isOpen);
  };

  return (
    <>
      <HeaderLandingStyle>
        <div className="dark-bg" />
        <div className="logo--wrapper">
          <img className="main-logo" src="/logo-white.svg" alt="Logo White" />
          {!isOpen && (
            <div className={isOpen ? 'menu-icon active' : 'menu-icon not-active'}>
              <ImageIcon onClick={toggleMenuBar} src="/icon/menu_icon.svg" className="icon--toggle" />
            </div>
          )}
          <div className="menu-icon">
            <ImageIcon onClick={toggleMenuBar} src="/icon/menu_icon.svg" className="icon--toggle" />
          </div>
        </div>
        <div className="login--wrapper">
          <div className="caption">The best way to<br></br>get your perfect<br></br>holiday photos</div>
          <LoginForm className="login--control" />
        </div>
      </HeaderLandingStyle>
      <MenuBarStyle className={`${isOpen ? 'active' : 'not-active'}`}>
        <div className="menu-holder">
          <div className="menu-header">
            <img className="menu-logo" src="/icon/logo-yellow.svg" alt="Logo" />
          </div>
          <div css={css`
            position: absolute;
            top: 44px;
            right: 32px;
          `}>
            <ImageIcon onClick={toggleMenuBar} src="/icon/menu_icon.svg" className="icon--toggle" />
          </div>
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/photographer">Photographers</a>
            </li>
            <li>
              <a href="/aboutus">About</a>
            </li>
            <li>
              <a href="/contact">Contact</a>
            </li>
            <li>
              <a href="/terms-and-conditions">Terms</a>
            </li>
            <li>
              <a href="/privacy-policy">Privacy</a>
            </li>
            <li>
              <a href="/cookie-policy">Cookies</a>
            </li>
            <li>
              <a href="/faq">FAQ</a>
            </li>
          </ul>
        </div>
      </MenuBarStyle>
    </>
  );
};

const HeaderClientUsing = (props: HeaderProps) => {
  const { refs, saveRefAction, isShowMorePhotos } = props;

  const { cartList, sources } = useSelector((rootState: RootState) => rootState.galleryReducer);

  const dispatch = useDispatch();

  const openNav = () => {
    refs.navRef.classList.add('active--nav');
    refs.mainRef.classList.add('main--open--nav');
    refs.mainRef.style.transition = 'all 0.3s';
    refs.mainRef.style.width = `calc(100% - ${navWidth})`;
    dispatch(checkOpenNav(true));
  };

  return (
    <HeaderClientUsingStyle
      ref={r => {
        if (!r) return;
        saveRefAction('headerRef', r);
      }}
    >
      <div className="header--wrapper">
        {isShowMorePhotos ? (
          <button
            type="button"
            className="back"
            onClick={() => {
              checkDetailPage(false);
              dispatch(checkDetailPage(false));
            }}
          >
            <ImageIcon className="back-icon" src="/icon/black_left_arrow.svg" />
            <p>Back to your photos</p>
          </button>
        ) : (
            <img
              className="main-logo"
              src="/logo-navy.svg"
              alt="Logo Navy"
              onClick={() => {
                navigate('/');
              }}
            />
          )}
        <div className="feature-group">
          <button
            type="button"
            className="add-all-photos"
            onClick={() => {
              if (cartList.length < sources.filter(photo => !photo.purchased).length) {
                eventEmitter.emit('add-all-photos', true);
              } else {
                eventEmitter.emit('add-all-photos', false);
              }
            }}
          >
            {cartList.length === sources.filter(photo => !photo.purchased).length && sources.length > 0 && cartList.length > 0 && <ImageIcon className="added-photos" src="/icon/added.svg" />}
            {cartList.length === sources.filter(photo => !photo.purchased).length && sources.length > 0 && cartList.length > 0 ? 'All photos added' : 'Add all photos to cart'}
          </button>
          <ImageIcon
            css={css`
              width: 29px;
              height: 25px;
          `}
            isButton src="/feature-gallery/cart.svg"
            alt="Cart feature"
            onClick={openNav} />
        </div>
      </div>
    </HeaderClientUsingStyle>
  );
};

const HeaderFAQPage = () => {
  const [isOpen, setOpen] = useState(false);

  const toggleMenuBar = () => {
    const bodyEl = document.body;
    if (!isOpen) {
      bodyEl.style.overflow = 'hidden';
    } else {
      bodyEl.style.overflow = 'auto';
    }
    setOpen(!isOpen);
  };
  return (
    <HeaderFAQPageUsingStyle>
      <div className="header-container">
        <img
          className="main-logo"
          src="/icon/logo-white-yellow.svg"
          alt="Logo"
          onClick={() => {
            navigate('/');
          }} />

        <div className={isOpen ? 'menu-icon active' : 'menu-icon not-active'}>
          <ImageIcon onClick={toggleMenuBar} src="/icon/menu_icon.svg" className="icon--toggle" />
        </div>
      </div>

      <MenuBarStyle className={`${isOpen ? 'active' : 'not-active'}`}>
        <div className="menu-holder">
          <div className="menu-header">
            <img className="menu-logo" src="/icon/logo-yellow.svg" alt="Logo" />
          </div>
          <div className="menu-icon">
            <ImageIcon onClick={toggleMenuBar} src="/icon/menu_icon.svg" className="icon--toggle" />
          </div>
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/photographer">Photographers</a>
            </li>
            <li>
              <a href="/aboutus">About</a>
            </li>
            <li>
              <a href="/contact">Contact</a>
            </li>
            <li>
              <a href="/terms-and-conditions">Terms</a>
            </li>
            <li>
              <a href="/privacy-policy">Privacy</a>
            </li>
            <li>
              <a href="/cookie-policy">Cookies</a>
            </li>
            <li>
              <a href="/faq">FAQ</a>
            </li>
          </ul>
        </div>
      </MenuBarStyle>
    </HeaderFAQPageUsingStyle>
  );
};

const Header: FunctionComponent<HeaderProps> = props => {
  const { isLandingPage, isClientPage, isPhotographerPage, isGuestPage, isRegisterPage, isFAQPage, isContactPage, isAboutUsPage, isCheckoutPage } = props;

  if (isLandingPage) {
    return <HeaderLanding />;
  }
  if (isClientPage) {
    return <HeaderClientUsing {...props} />;
  }
  if (isPhotographerPage) {
    return <HeadingPhotographerUsing {...props} />;
  }
  if (isGuestPage) {
    return <HeaderForGuest {...props} />;
  }
  if (isRegisterPage) {
    return <HeadingRegisterUsing {...props} />;
  }
  if (isFAQPage || isContactPage || isAboutUsPage) {
    return <HeaderFAQPage />;
  }
  if (isCheckoutPage) {
    return <HeaderCheckoutUsing />;
  }
  return null;
};

const mapStateToProps = (state: RootState) => ({
  refs: state.galleryReducer.refs,
  isShowMorePhotos: state.galleryReducer.isSlider,
  isOpenNav: state.galleryReducer.isOpenNav,
});

const mapDispatchToProps = {
  saveRefAction: saveRef,
  checkDetailPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
